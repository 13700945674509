
.inputConfirmacion {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 270px;
    height: 36px;
    padding-left: 9px;
    border-radius: 12px;
    gap: 12px;
    background: #F3F2F4;
    border-style: none;
    outline: none;
    border: 1px solid #e7e6e6; 
} 

.element-Confirmacion {
    display: flex;
    flex-direction: column; 
    align-items: center;
    align-content: center;
    border: 1.3px solid #e7e6e6; 
    width: 300px;
    border-top: none;
    border-radius: 0px 0px 5px 5px;
    padding: 13px 0px 13px 0px;
}

.containerPrincipal {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.confirmacion {
    font-family: 'Bahnschrift SemiBold';
    font-style: normal; 
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    color: #000000;
}


.containerConfirmacion {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1.3px solid #E6E6E6;
    border-radius: 5px 5px 0px 0px;
    padding: 11px;
    width: 278px;
    align-items: center;
    border-bottom: none;
}

.label {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    align-content: start;
    text-align: left;
}
.containerPoliticas {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 11px;
    width: 80%;
    align-items: ju;
    border-bottom: none;
}
 /* Estilos para la lista */
 ul {
    list-style-type: none;
    margin: 20px 0; /* Espacio exterior */
    padding: 0; /* Eliminar padding por defecto */
  }

  /* Estilos para los elementos de la lista */
  ul li {
    margin-bottom: 10px; /* Espacio entre elementos */
    padding: 5px 10px; /* Espacio dentro de cada elemento */   
    border-radius: 5px; /* Bordes redondeados */
    text-align: left;
  }
  .inputConfirmacionText {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 270px;
    height: 80px;
    padding-left: 9px;
    border-radius: 12px;
    gap: 12px;
    background: #F3F2F4;
    border-style: none;
    outline: none;
    border: 1px solid #e7e6e6; 
}

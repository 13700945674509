.logoCompany{
    margin-top: 150px;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 19px;
}

.input {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 272px;
    height: 36px;
    padding-left: 9px;
    border-radius: 12px;
    gap: 12px;
    background: #F3F2F4;
    border-style: none;
    outline: none;
    /* outline: #00F2D7; */
    border: 1px solid #e7e6e6; 
}

.title {
    /* align-items: flex-start;
    align-self: left;  */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* text-align: left; */
}


/* Quita las fechas del input de telefono. */
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}


/* Select */

.options { /* Permite dejar el tamaño de las opciones en el tamaño indicado*/
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 280px;
    height: 36px;
}

.css-1un73jv-control { /* Permite dejar la seleccion en el tamaño indicado */
    width: 280px;
    height: 36px;
}


.css-1vom5ua-control{
    width: 280px;
    height: 36px;
}

.css-m2ag68-control{ /* Permite dejar la seleccion en el tamaño indicado */
    width: 285px;
    height: 36px;
}


@media (max-width:350px) {
    .options {
        width: 207px;
    }

    .css-1h06qz8-control{
        width: 207px;
    }
    
    .css-1un73jv-control {
        width: 207px;
    }
}


/* Fecha */

.date {
    width: 272px;
    height: 20px;
    border-radius: 10px;
    padding: 9px;
    font-size: 15px;
    border: none;
    border: 1.5px solid #e7e6e6; 
    outline: none;
    font-family: 'Calibri', 'Helvetica Neue', sans-serif; 
}